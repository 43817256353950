import React,{useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDashboardData } from '../slice/contactUsSlice';
import { RiMedicineBottleLine } from "react-icons/ri";
import { FaFileImage ,FaUsers, FaSlidersH ,FaCalendarAlt} from "react-icons/fa";
function Home() {
  const dispatch = useDispatch()
  const Data = useSelector((state) => state.contactus);


  const { table_counts } = Data.data;
  useEffect(() => {
    dispatch(getDashboardData())
  }, [dispatch])


  return (
    <>
      <div className="container-fluid px-lg-5 mt-5">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 mainheadig">
          <h1 className="h3 mb-0 text-white">Dashboard</h1>         
        </div>
        <div className="row  mt-5">
           <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                      Total Appointments</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{table_counts?.appointment}</div>
                  </div>
                  <div className="col-auto">
                  <FaCalendarAlt className='fa-2x text-gray-300' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Medicines</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{table_counts?.product}</div>
                  </div>
                  <div className="col-auto">
                        <RiMedicineBottleLine className='fa-2x text-gray-300' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Total Gallery</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{table_counts?.gallery}</div>
                  </div>
                  <div className="col-auto">
                  <FaFileImage className='fa-2x text-gray-300' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-info shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Total Media
                    </div>
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto">
                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{table_counts?.media}</div>
                      </div>
                      <div className="col">
                        <div className="progress progress-sm mr-2">
                          <div className="progress-bar bg-info" role="progressbar"
                            aria-valuenow="50" aria-valuemin="0"
                            aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                  <FaFileImage className='fa-2x text-gray-300' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                      Total Banner</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{table_counts?.banner}</div>
                  </div>
                  <div className="col-auto">
                  <FaSlidersH className='fa-2x text-gray-300' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Contact US</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{table_counts?.contactus}</div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-comments fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Total Team</div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{table_counts?.ourteam}</div>
                  </div>
                  <div className="col-auto">
                  <FaUsers className='fa-2x text-gray-300' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home