import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOneOurteam, updateOurteam } from 'slice/ourteamSlice';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';

const UpdateOurteam = () => {
    const { id } = useParams(); 
    const dispatch = useDispatch();
    const nevigate = useNavigate();
    const { loading, data } = useSelector((state) => state.ourteam);
    const [formData, setFormData] = useState({
        name: '',
        role: '',
        description: '',
        image: null,
        imageUpdate: null
    });

    useEffect(() => {
        dispatch(getOneOurteam(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setFormData(data);
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? e.target.files[0] : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                formDataToSend.append(key, formData[key]);
            }

            dispatch(updateOurteam({ id: id, data: formDataToSend }))
            toast('Our team updated successfully!');
            nevigate('/ourteam')

        } catch (error) {
            console.error('There was a problem updating the product:', error.message);
        }
    };


    return (
        <>
            <div className='container card my-5'>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-sm-4 mt-2'>
                            <label htmlFor='productName'>Name</label>
                            <input type='text' id='productName' name='name' defaultValue={formData.name} className='form-control' onChange={handleChange} />
                        </div>
                        <div className='col-sm-4 mt-2'>
                            <label htmlFor='role'>Role</label>
                            <input defaultValue={formData.role} className='form-control' onChange={handleChange} name='role' id='role'/>
                        </div>
                        <div className='col-sm-4 mt-2'>
                            <label htmlFor='description'>Description</label>
                            <input type='text' id='description' name='description' defaultValue={formData.description} className='form-control' onChange={handleChange} />
                        </div>
                        <div className='col-sm-12 mt-2'>
                            <label htmlFor='productName'>Image</label>
                            <input type='file' id='productName' name='imageUpdate' className='form-control' onChange={handleChange} />
                        </div>
                        <div className='col-sm-4'>
                                <img src={`${process.env.REACT_APP_BACKEND}team/${formData.image}`} alt=''/>
                        </div>
                    </div>
                    <div className='mt-3 text-right'>
                        <button className='btn btn-success' disabled={loading}>Update Our Team</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UpdateOurteam;