import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getContactUs, removeContactUs } from '../slice/contactUsSlice';
import AdminTable from 'common/AdminTable';
import TruncatedMessage from 'common/TruncatedMessage'
const Contactus = () => {
    const dispatch = useDispatch();
    const cartData = useSelector((state) => state.contactus);
    
    const tableHeaders = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Address',
            accessor: 'address',
        },
        {
            Header: 'Subject',
            accessor: 'subject',
        },
        {
            Header: 'Mobile',
            accessor: 'mobile',
        },
        {
            Header: 'Message',
            accessor: 'message',
            Cell: ({ row }) => <TruncatedMessage text={row.original.message} maxLength={10} />
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
              
                <button
                    className="btn removebtn"
                    onClick={() => dispatch(removeContactUs(row.original.id))}
                >
                    Remove
                </button>
            ),
        },
    ];
    useEffect(() => {
        dispatch(getContactUs());
    }, [dispatch]);

    return (
        <>
            <div className="container-fluid mt-5">
                <div className="mainheadig mx-4">
                    <h4 className="text-white font-weight-bold">Contact List</h4>
                </div>
                {/* Conditionally render AdminTable */}
                {cartData?.data?.length > 0 && (
                    <AdminTable tableHeaders={tableHeaders} tableData={cartData.data} />
                )}
            </div>
        </>
    );
};

export default Contactus;
