// Layout.js
import Button from 'components/Button';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logout } from 'slice/userSlice';
import { useDispatch } from 'react-redux';
import { IoPersonCircleSharp } from "react-icons/io5";
import { MdContacts } from "react-icons/md";
import { FaFileImage, FaUsers, FaSlidersH, FaCalendarAlt } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { RiMedicineBottleLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import logo from '../asserts/img/dphclogo.png'
// import Header from './Header';
// import Footer from './Footer';
// import { linklists, headersClasses } from './RouterList';
const currentUrl = window.location.pathname;
const lastSegment = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

const Layout = ({ children }) => {
    const [toggled, setToggle] = useState(false);
    const [openMenu, setOpenMenu] = useState(null); 
    const dispatch = useDispatch();
    const nevigate = useNavigate();

    const toggleMenu = (menu) => {
        setOpenMenu(openMenu === menu ? null : menu); 
    };

    const logoutHandler = async (e) => {
        e.preventDefault();
        try {
            dispatch(logout());
            nevigate('/signin');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <div id="wrapper">
                <ul className={toggled ? 'navbar-nav sidebar sidebar-dark toggled' : 'navbar-nav sidebar sidebar-dark accordion'} id="accordionSidebar">
                    <Link className="text-decoration-none justify-content-start" to="/dashboard">
                        <div className="text-center">
                          <img src={logo} alt='adminlogo' className='img-fluid adminlogo' />
                        </div>
                    </Link>
                    {/* <hr className="sidebar-divider my-0" /> */}
                    <li className="nav-item text-dark">
                        <Link className="nav-link" to="/dashboard">
                            <MdDashboard className='mr-1' />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    {/* <hr className="sidebar-divider text-light" /> */}
                    {/* <div className="sidebar-heading text-dark">
                        Interface
                    </div> */}

                    {/* Appointments */}
                    <li className={`nav-item text-dark ${lastSegment === 'appointments' ? 'active' : ''}`}>
                        <Link
                            className="nav-link text-dark collapsed"
                            href="#"
                            data-toggle="collapse"
                            aria-expanded={openMenu === 'appointments'}
                            onClick={() => toggleMenu('appointments')}
                        >
                            <FaCalendarAlt className='mr-1' />
                            <span>Appointments</span>
                        </Link>
                        <div
                            id="appointments"
                            className={`collapse ${openMenu === 'appointments' ? 'show' : ''}`}
                            aria-labelledby="headingTwo"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/appointments">Appointments List</Link>
                            </div>
                        </div>
                    </li>

                    
                    {/* Customer Order list */}
                    <li className={`nav-item text-dark ${lastSegment === 'customerorder' ? 'active' : ''}`}>
                        <Link
                            className="nav-link text-dark collapsed"
                            href="#"
                            data-toggle="collapse"
                            aria-expanded={openMenu === 'gallery'}
                            onClick={() => toggleMenu('customerorder')}
                        >
                            <FaFileImage className='mr-1' />
                            <span>Order List</span>
                        </Link>
                        <div
                            id="gallery"
                            className={`collapse ${openMenu === 'customerorder' ? 'show' : ''}`}
                            aria-labelledby="headingTwo"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/customerorder">Order 
                                List</Link>
                            </div>
                        </div>
                    </li>


                    {/* Medicines */}
                    <li className={`nav-item text-dark ${lastSegment === 'medicines' ? 'active' : ''}`}>
                        <Link
                            className="nav-link text-dark collapsed"
                            href="#"
                            data-toggle="collapse"
                            aria-expanded={openMenu === 'medicines'}
                            onClick={() => toggleMenu('medicines')}
                        >
                            <RiMedicineBottleLine className='mr-1' />
                            <span>Medicines</span>
                        </Link>
                        <div
                            id="medicines"
                            className={`collapse ${openMenu === 'medicines' ? 'show' : ''}`}
                            aria-labelledby="headingTwo"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/products/add">Add Medicines</Link>
                                <Link className="collapse-item" to="/products">Medicines List</Link>
                            </div>
                        </div>
                    </li>

                    {/* Gallery */}
                    <li className={`nav-item text-dark ${lastSegment === 'gallery' ? 'active' : ''}`}>
                        <Link
                            className="nav-link text-dark collapsed"
                            href="#"
                            data-toggle="collapse"
                            aria-expanded={openMenu === 'gallery'}
                            onClick={() => toggleMenu('gallery')}
                        >
                            <FaFileImage className='mr-1' />
                            <span>Gallery</span>
                        </Link>
                        <div
                            id="gallery"
                            className={`collapse ${openMenu === 'gallery' ? 'show' : ''}`}
                            aria-labelledby="headingTwo"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/gallery/add">Add Gallery</Link>
                                <Link className="collapse-item" to="/gallery">Gallery List</Link>
                            </div>
                        </div>
                    </li>

                    {/* Media & News */}
                    <li className={`nav-item text-dark ${lastSegment === 'media' ? 'active' : ''}`}>
                        <Link
                            className="nav-link text-dark collapsed"
                            href="#"
                            data-toggle="collapse"
                            aria-expanded={openMenu === 'media'}
                            onClick={() => toggleMenu('media')}
                        >
                            <FaFileImage className='mr-1' />
                            <span>Media & News</span>
                        </Link>
                        <div
                            id="media"
                            className={`collapse ${openMenu === 'media' ? 'show' : ''}`}
                            aria-labelledby="headingTwo"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/media/add">Add Media</Link>
                                <Link className="collapse-item" to="/media">Media List</Link>
                            </div>
                        </div>
                    </li>

                    {/* Our Team */}
                    <li className={`nav-item text-dark ${lastSegment === 'ourteam' ? 'active' : ''}`}>
                        <Link
                            className="nav-link text-dark collapsed"
                            href="#"
                            data-toggle="collapse"
                            aria-expanded={openMenu === 'ourteam'}
                            onClick={() => toggleMenu('ourteam')}
                        >
                            <FaUsers className='mr-1' />
                            <span>Our Team</span>
                        </Link>
                        <div
                            id="ourteam"
                            className={`collapse ${openMenu === 'ourteam' ? 'show' : ''}`}
                            aria-labelledby="headingTwo"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/ourteam/add">Add Our Team</Link>
                                <Link className="collapse-item" to="/ourteam">Our Team List</Link>
                            </div>
                        </div>
                    </li>

                    {/* Banner */}
                    <li className={`nav-item text-dark ${lastSegment === 'banner' ? 'active' : ''}`}>
                        <Link
                            className="nav-link text-dark collapsed"
                            href="#"
                            data-toggle="collapse"
                            aria-expanded={openMenu === 'banner'}
                            onClick={() => toggleMenu('banner')}
                        >
                            <FaSlidersH className='mr-1' />
                            <span>Banner</span>
                        </Link>
                        <div
                            id="banner"
                            className={`collapse ${openMenu === 'banner' ? 'show' : ''}`}
                            aria-labelledby="headingTwo"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item" to="/banner/add">Add Banner</Link>
                                <Link className="collapse-item" to="/banner">Banner List</Link>
                            </div>
                        </div>
                    </li>

                    {/* <hr className="sidebar-divider d-none d-md-block text-dark" /> */}

                    {/* Contact Us */}
                    <li className={`nav-item ${lastSegment === 'contactus' ? 'active' : ''}`}>
                        <Link
                            className="nav-link collapsed"
                            href="#"
                            data-toggle="collapse"
                            aria-expanded={openMenu === 'contactus'}
                            onClick={() => toggleMenu('contactus')}
                        >
                            <MdContacts className='mr-1' />
                            <span>Contact Us</span>
                        </Link>
                        <div
                            id="contactus"
                            className={`collapse ${openMenu === 'contactus' ? 'show' : ''}`}
                            aria-labelledby="headingTwo"
                        >
                            <div className="bg-white py-2 collapse-inner rounded">
                                <Link className="collapse-item text-dark" to="/contactus">Contact Us List</Link>
                            </div>
                        </div>
                    </li>

                    <div className="text-center text-dark d-none d-md-inline mt-5">
                        <button className="rounded-circle border-0 text-dark" id="sidebarToggle" onClick={()=> setToggle(!toggled)}></button>
                    </div>
                </ul>

                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                            <button id="sidebarToggleTop" onClick={()=> setToggle(!toggled)} className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>
                            <ul className="navbar-nav ml-auto">
                                {/* <div className="topbar-divider d-none d-sm-block"></div> */}
                                <li className="nav-item dropdown no-arrow">
                                    <Link className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="mr-2 d-none d-lg-inline admintext small">Admin</span>
                                        <IoPersonCircleSharp size={20} style={{ color: "#073980" }} />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                        aria-labelledby="userDropdown">
                                        <div className="dropdown-divider"></div>
                                        <Button className="dropdown-item" text='Logout' onclickfun={logoutHandler} />
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
