import { put, takeLatest } from 'redux-saga/effects';
import { signup, signin, success, failed } from '../slice/userSlice';
import { postRequest, getRequest, deleteRequest, patchRequest } from './ApiInstence';
import { toast } from 'react-toastify';
import { getContactUs, successContactUs, failedContactUs, removeContactUs, getDashboardData } from '../slice/contactUsSlice';
import { getAppointment, successAppointment, failedAppointment, removeAppointment ,getDashboardDataa} from '../slice/appointmentSlice';
import { getEnquire, successEnquire, failedEnquire, removeEnquire } from '../slice/enquireSlice';
import { addProduct, getProduct, updateProduct, successProduct, removeProduct, getOneProduct, searchProductByName } from '../slice/productSlice';
import { getGallery, successGallery, removeGallery, addGallery, updateGallery, getOneGallery } from '../slice/gallerySlice';
import { getMedia, successMedia, removeMedia, addMedia, updateMedia, getOneMedia } from '../slice/mediaSlice';
import { getBanner, successBanner, removeBanner, addBanner, updateBanner, getOneBanner } from '../slice/bannerSlice';
import { failedCareer, getCareer, removeCareer, successCareer } from 'slice/careerSlice';
import { getOurteam, successOurteam, removeOurteam, addOurteam, updateOurteam, getOneOurteam, failedOurteam } from 'slice/ourteamSlice';
import { getCustomer, successCustomer, removeCustomer, addCustomer, updateCustomer, getOneCustomer, searchByMobile } from '../slice/customerSlice';
import { addOrderListDB, getOrderListDB, getOrderDeatilsDB, successOrderListDB, failedOrderListDB, updateOrderListDB } from '../slice/orderSliceDB';
import { updateOrderAPI } from './orderAPI/orderAPI';


// Generator function
function* signinUser({ payload }) {
  try {
    // You can also export the axios call as a function.
    let response = yield postRequest('admin/signin', payload, {
      withCredentials: true, // Include cookies in the request
    });
    if (response == undefined) {
      response = {
        message: 'something wrong try after sometime'
      }
    }
    if (response?.status == 200) {
      yield put(success(response));
    } else {
      yield put(failed(response));
    }
  } catch (error) {
    yield put(failed(error));
  }
}

function* signupUser({ payload }) {
  try {
    const response = yield postRequest('register', payload);
    if (response?.status == 200) {
      yield put(success(response?.data));
    } else {
      yield put(failed(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getContactUsFun({ payload }) {
  try {
    const response = yield getRequest('contactus', payload);
    if (response?.status == 200) {
      yield put(successContactUs(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getDashboardDataFun({ payload }) {
  try {
    const response = yield getRequest('dashboard', payload);
    if (response?.status == 200) {
      yield put(successContactUs(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}


function* getProductFun() {
  try {
    const response = yield getRequest('product');
    if (response?.status == 200) {
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneProductFun({ payload }) {
  try {
    const response = yield getRequest(`product/one/${payload}`);
    if (response?.status == 200) {
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeContactUsFun({ payload }) {
  try {
    const response = yield deleteRequest(`contactus/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('contactus', payload);
      yield put(successContactUs(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeProductFun({ payload }) {
  try {
    const response = yield deleteRequest(`product/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('product', payload);
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* addProductFun({ payload }) {
  try {
    const response = yield postRequest(`product`, payload);
    if (response?.status == 200) {
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* searchProductByNameFun({ payload }) {
  try {
    const response = yield getRequest(`product/search?product=${payload}`);
    if (response?.status == 200) {
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateProductFun({ payload }) {
  try {
    const response = yield patchRequest(`product/${payload.id}`, payload.data);
    if (response?.status == 200) {
      yield put(successProduct(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

//Gallery Functions 
function* getGalleryFun() {
  try {
    const response = yield getRequest('gallery');
    if (response?.status == 200) {
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneGalleryFun({ payload }) {
  try {
    const response = yield getRequest(`gallery/one/${payload}`);
    if (response?.status == 200) {
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeGalleryFun({ payload }) {
  try {
    const response = yield deleteRequest(`gallery/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('gallery', payload);
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* addGalleryFun({ payload }) {
  try {
    const response = yield postRequest(`gallery`, payload);
    if (response?.status == 200) {
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateGalleryFun({ payload }) {
  try {
    const response = yield patchRequest(`gallery/${payload.id}`, payload.data);
    if (response?.status == 200) {
      yield put(successGallery(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}
//Media Functions 
function* getMediaFun() {
  try {
    const response = yield getRequest('media');
    if (response?.status == 200) {
      yield put(successMedia(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneMediaFun({ payload }) {
  try {
    const response = yield getRequest(`media/one/${payload}`);
    if (response?.status == 200) {
      yield put(successMedia(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeMediaFun({ payload }) {
  try {
    const response = yield deleteRequest(`media/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('media', payload);
      yield put(successMedia(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* addMediaFun({ payload }) {
  try {
    const response = yield postRequest(`media`, payload);
    if (response?.status == 200) {
      yield put(successMedia(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateMediaFun({ payload }) {
  try {
    const response = yield patchRequest(`media/${payload.id}`, payload.data);
    if (response?.status == 200) {
      yield put(successMedia(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}
// Enquire
function* getEnquireFun({ payload }) {
  try {
    const response = yield getRequest('enquire', payload, payload.header);
    if (response?.status == 200) {
      yield put(successEnquire(response?.data));
    } else {
      yield put(failedEnquire(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeEnquireFun({ payload }) {
  try {
    const response = yield deleteRequest(`enquire/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('enquire', payload);
      yield put(successEnquire(response?.data));
    } else {
      yield put(failedEnquire(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getCareerFun({ payload }) {
  try {
    const response = yield getRequest('career', payload);
    if (response?.status == 200) {
      yield put(successCareer(response?.data));
    } else {
      yield put(failedCareer(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeCareerFun({ payload }) {
  try {
    const response = yield deleteRequest(`career/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('career', payload);
      yield put(success(response?.data));
    } else {
      yield put(failedCareer(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}



//Banner Start
function* getBannerFun() {
  try {
    const response = yield getRequest('banner');
    if (response?.status == 200) {
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneBannerFun({ payload }) {
  try {
    const response = yield getRequest(`banner/one/${payload}`);
    if (response?.status == 200) {
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeBannerFun({ payload }) {
  try {
    const response = yield deleteRequest(`banner/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('banner', payload);
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* addBannerFun({ payload }) {
  try {
    const response = yield postRequest(`banner`, payload);
    if (response?.status == 200) {
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateBannerFun({ payload }) {
  try {
    const response = yield patchRequest(`banner/${payload.id}`, payload.data);
    if (response?.status == 200) {
      yield put(successBanner(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

//Banner End 


//Ourteam Start
function* getOurteamFun() {
  try {
    const response = yield getRequest('ourteam');
    if (response?.status == 200) {
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneOurteamFun({ payload }) {
  try {
    const response = yield getRequest(`ourteam/one/${payload}`);
    if (response?.status == 200) {
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeOurteamFun({ payload }) {
  try {
    const response = yield deleteRequest(`ourteam/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('ourteam', payload);
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* addOurteamFun({ payload }) {
  try {
    const response = yield postRequest(`ourteam`, payload);
    if (response?.status == 200) {
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateOurteamFun({ payload }) {
  try {
    let header = {
      'Content-Type': 'multipart/form-data'
    }
    const response = yield patchRequest(`ourteam/${payload.id}`, payload.data, header);
    if (response?.status == 200) {
      yield put(successOurteam(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

//Ourteam End 


// customer start
function* addCustomerFun({ payload }) {
  try {
    const response = yield postRequest(`customer`, payload);
    if (response?.status == 200) {
      yield put(successCustomer(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}


function* getCustomerFun() {
  try {
    const response = yield getRequest('customer');
    if (response?.status == 200) {
      yield put(successCustomer(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* removeCustomerFun({ payload }) {
  try {
    const response = yield deleteRequest(`customer/${payload}`);
    if (response?.status == 200) {
      const response = yield getRequest('customer', payload);
      yield put(successCustomer(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOneCustomerFun({ payload }) {
  try {
    const response = yield getRequest(`customer/one/${payload}`);
    if (response?.status == 200) {
      yield put(successCustomer(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* updateCustomerFun({ payload }) {
  try {
    let header = {
      'Content-Type': 'multipart/form-data'
    }
    const response = yield patchRequest(`customer/${payload.id}`, payload.data, header);
    if (response?.status == 200) {
      yield put(successCustomer(response?.data));
    } else {
      yield put(failedOurteam(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* searchByMobileFun({ payload }) {
  try {
    const response = yield getRequest(`customer/search?name=${payload}`);
    if (response?.status == 200) {
      yield put(successCustomer(response?.data));
    } else {
      yield put(failedContactUs(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

// customer end

// order list DB start
function* addOrderListDBFun({ payload }) {
  try {
    const response = yield postRequest(`order`, payload);
    if (response?.status == 200) {
      yield put(successOrderListDB(response?.data));
    } else {
      yield put(failedOrderListDB(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOrderListDBFun() {
  try {
    const response = yield getRequest('order');
    if (response?.status == 200) {
      yield put(successOrderListDB(response?.data));
    } else {
      yield put(failedOrderListDB(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

function* getOrderDeatilsDBFun({payload}) {
  try {
    const response = yield getRequest(`order/order_details/${payload}`);
    if (response?.status == 200) {
      yield put(successOrderListDB(response?.data));
    } else {
      yield put(failedOrderListDB(response));
    }
  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }
}

// Fetch Appointments
function* getAppointmentFun({ payload }) {
  try {
    const response = yield getRequest('appointments', payload);
    if (response?.status == 200) {
      yield put(successAppointment(response?.data));
    } else {
      yield put(failedAppointment(response?.data));
      toast(response?.data?.message || 'Failed to fetch appointments');
    }
  } catch (error) {
    console.log(error);
    toast(error.response?.data?.message || error.message);
  }
}

// Fetch Dashboard Data
function* getDashboardDataaFun({ payload }) {
  try {
    const response = yield getRequest('dashboard', payload);
    if (response?.status == 200) {
      yield put(successAppointment(response?.data));
    } else {
      yield put(failedAppointment(response?.data));
      toast(response?.data?.message || 'Failed to fetch dashboard data');
    }
  } catch (error) {
    console.log(error);
    toast(error.response?.data?.message || error.message);
  }
}

// Remove Appointment
function* removeAppointmentFun({ payload }) {
  try {
    if (!payload) {
      throw new Error('Appointment ID is missing');
    }
    const deleteResponse = yield deleteRequest(`appointments/${payload}`);
    if (deleteResponse?.status >= 200 && deleteResponse?.status < 300) {
      // console.log('lllllllll', deleteResponse)
      yield getAppointmentFun({payload})
      toast('Appointment deleted successfully');
    } else {
      // If delete failed, dispatch failure action and show the error toast
      yield put(failedAppointment(deleteResponse?.data));
      toast(deleteResponse?.data?.message || 'Failed to delete appointment');
    }

  } catch (error) {
    console.log(error);
    toast(error.response?.data?.message || error.message);
  }
}

// function* removeAppointmentFun({ payload }) {
//   try {
//     const deleteResponse = yield deleteRequest(`appointments/${payload}`);
//     if (deleteResponse?.status == 200) {
//       const appointmentsResponse = yield getRequest('appointments');
//       yield put(successAppointment(appointmentsResponse?.data));
//     } else {
//       yield put(failedAppointment(deleteResponse?.data));
//       toast(deleteResponse?.data?.message || 'Failed to delete appointment');
//     }
//   } catch (error) {
//     console.log(error);
//     toast(error.response?.data?.message || error.message);
//   }
// }



// appointemnt
// order list db end 


// Generator function
export function* watchGetUser() {
  yield takeLatest(signin, signinUser);
  yield takeLatest(signup, signupUser);

  // contact us
  yield takeLatest(getContactUs, getContactUsFun);
  yield takeLatest(getDashboardData, getDashboardDataFun);
  yield takeLatest(removeContactUs, removeContactUsFun);

 // appointemnt
  yield takeLatest(getAppointment, getAppointmentFun);
  yield takeLatest(getDashboardDataa, getDashboardDataaFun);
  yield takeLatest(removeAppointment, removeAppointmentFun);

  //Enquire
  yield takeLatest(getEnquire, getEnquireFun);
  yield takeLatest(removeEnquire, removeEnquireFun);

  yield takeLatest(addProduct, addProductFun);
  yield takeLatest(getProduct, getProductFun);
  yield takeLatest(getOneProduct, getOneProductFun);
  yield takeLatest(searchProductByName, searchProductByNameFun);
  yield takeLatest(removeProduct, removeProductFun);
  yield takeLatest(updateProduct, updateProductFun);

  //gallery
  yield takeLatest(addGallery, addGalleryFun);
  yield takeLatest(getGallery, getGalleryFun);
  yield takeLatest(removeGallery, removeGalleryFun);
  yield takeLatest(getOneGallery, getOneGalleryFun);
  yield takeLatest(updateGallery, updateGalleryFun);

   //media
   yield takeLatest(addMedia, addMediaFun);
   yield takeLatest(getMedia, getMediaFun);
   yield takeLatest(removeMedia, removeMediaFun);
   yield takeLatest(getOneMedia, getOneMediaFun);
   yield takeLatest(updateMedia, updateMediaFun);

  //Banner
  yield takeLatest(addBanner, addBannerFun);
  yield takeLatest(getBanner, getBannerFun);
  yield takeLatest(removeBanner, removeBannerFun);
  yield takeLatest(getOneBanner, getOneBannerFun);
  yield takeLatest(updateBanner, updateBannerFun);

  //Our Team
  yield takeLatest(addOurteam, addOurteamFun);
  yield takeLatest(getOurteam, getOurteamFun);
  yield takeLatest(removeOurteam, removeOurteamFun);
  yield takeLatest(getOneOurteam, getOneOurteamFun);
  yield takeLatest(updateOurteam, updateOurteamFun);

  // Career 
  yield takeLatest(getCareer, getCareerFun);
  yield takeLatest(removeCareer, removeCareerFun);

  //Customer
  yield takeLatest(addCustomer, addCustomerFun);
  yield takeLatest(getCustomer, getCustomerFun);
  yield takeLatest(removeCustomer, removeCustomerFun);
  yield takeLatest(getOneCustomer, getOneCustomerFun);
  yield takeLatest(updateCustomer, updateCustomerFun);
  yield takeLatest(searchByMobile, searchByMobileFun);

  yield takeLatest(getCareer, getCareerFun);
  yield takeLatest(removeCareer, removeCareerFun);

  // order DB
  yield takeLatest(addOrderListDB, addOrderListDBFun);
  yield takeLatest(getOrderListDB, getOrderListDBFun);
  yield takeLatest(getOrderDeatilsDB, getOrderDeatilsDBFun);
  yield takeLatest(updateOrderListDB, updateOrderAPI);

  

}