import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { addOurteam } from 'slice/ourteamSlice';

const AddOurteam = () => {
    const [formData, setFormData] = useState({
        name: '',
        role: '',
        description: '',
        image: null,
    });

    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.product);
    const nevigate = useNavigate()

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? e.target.files[0] : value
        }));

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        try {
            dispatch(addOurteam(formDataToSend))
            toast('Ourteam added successfully!')
            nevigate('/ourteam')
            setFormData({
                name: '',
                role: '',
                description: '',
                image: null,
            })
        } catch (error) {
            console.error('There was a problem adding the product:', error.message);
        }
    };

    return (
        <>
            <div className='container-fluid mt-5 '>
            <div className='mainheadig mx-4'>
                <h4 className='text-white font-weight-bold '>Add Our Team</h4>
            </div>
                <form onSubmit={handleSubmit}>
                    <div className='row mx-4'>
                        <div className='col-sm-12 mt-4'>
                            <label htmlFor='productName' className='adminlables'>Name</label>
                            <input type='text' id='productName' name='name' defaultValue={formData.name} className='form-control' onChange={handleChange} />
                        </div>
                        <div className='col-sm-12 mt-4'>
                            <label htmlFor='role' className='adminlables'>Role</label>
                            <input defaultValue={formData.role} className='form-control' onChange={handleChange} name='role' id='role'/>
                        </div>
                        {/* <div className='col-sm-12 mt-2'>
                            <label htmlFor='role'>Role</label>
                            <select defaultValue={formData.role} className='form-control' onChange={handleChange} name='role' id='role'>
                                <option key='hr' value='HR'>HR</option>
                                <option key='operation' value='Operation'>Operation</option>
                                <option key='sales' value='Sales'>Sales</option>
                            </select>
                        </div> */}
                        <div className='col-sm-12 mt-4'>
                            <label htmlFor='description' className='adminlables'>Description</label>
                            <input type='text' id='description' name='description' defaultValue={formData.description} className='form-control' onChange={handleChange} />
                        </div>
                        <div className='col-sm-12 mt-4' >
                            <label htmlFor='productName' className='adminlables'>Image</label>
                            <input type='file' id='productName' name='image' defaultValue={formData.image} className='form-control btn-block filebg' onChange={handleChange} />
                        </div>
                    </div>
                    <div className='mt-5 text-right mx-4'>
                        <button className='btn btn-lg addbtn' disabled={loading}>Add Our Team</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddOurteam