// store.js

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Import your slices and root saga
import counterSlice from '../slice/counterSlice';
import authSlice from '../slice/authSlice';
import productSlice from '../slice/productSlice';
import userSlice from '../slice/userSlice';
import orderSlice from '../slice/orderSlice';
import contactUsSlice from '../slice/contactUsSlice';
import appointmentSlice from '../slice/appointmentSlice';
import careerSlice from '../slice/careerSlice';
import gallerySlice from '../slice/gallerySlice';
import mediaSlice from '../slice/mediaSlice';
import enquireSlice from '../slice/enquireSlice';
import bannerSlice from '../slice/bannerSlice';
import ourteamSlice from '../slice/ourteamSlice';
import customerSlice from '../slice/customerSlice';
import orderSliceDB from '../slice/orderSliceDB';
import rootSaga from '../apis/rootSaga';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  counter: counterSlice,
  auth: authSlice,
  product: productSlice,
  gallery: gallerySlice,
  media: mediaSlice,
  enquire: enquireSlice,
  user: userSlice,
  order: orderSlice,
  orderDB: orderSliceDB,
  contactus: contactUsSlice,
  appointments:appointmentSlice,
  career: careerSlice,
  banner: bannerSlice,
  ourteam: ourteamSlice,
  customer: customerSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
      thunk: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
