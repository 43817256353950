import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAppointment, removeAppointment } from 'slice/appointmentSlice';
import AdminTable from 'common/AdminTable';
import TruncatedMessage from 'common/TruncatedMessage';
import { IoIosEye } from "react-icons/io";

const Appointment = () => {
    const [viewData, setVierData] = useState(null)
    const [deleteCall, setDeleteCall] = useState(false)
    const dispatch = useDispatch();
    const cartData = useSelector((state) => state.appointments);



    const tableHeaders = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: ' Patient Name',
            accessor: 'name',
        },
        {
            Header: 'Age',
            accessor: 'age',
        },
        {
            Header: 'Current Illness',
            accessor: 'illness',
        },

        // {
        //     Header: 'Consultation Type',
        //     accessor: 'appointment_type',
        // },
        {
            Header: 'Selected OPD',
            accessor: 'city',
        },
      
        {
            Header: 'Assign Doctor',
            accessor: 'assign_doctor',
        },
        {
            Header: 'Appointment Date',
            accessor: 'appointment_date',
        },
        {
            Header: 'Appointment Time',
            accessor: 'appointment_time',
        },
        {
            Header: 'Phone Number',
            accessor: 'patient_mobile',
        },
        {
            Header: 'Address',
            accessor: 'address',
        },
        {
            Header: 'Message',
            accessor: 'message',
            Cell: ({ row }) => <TruncatedMessage text={row.original.message} maxLength={10} />
        },

        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <button
                    className="btn removebtn"
                    onClick={() => {
                        setDeleteCall(!deleteCall)
                        dispatch(removeAppointment(row.original.id))
                    }
                    }
                >
                    Remove
                </button>
            ),
        },
        {
            Header: 'View',
            accessor: 'actions2',
            Cell: ({ row }) => (

                <button
                    className="btn btn-success"
                    onClick={() => setVierData(row)}
                    data-toggle="modal" data-target="#exampleModalCenter"
                >
                    <IoIosEye />
                </button>
            ),
        }
    ];
    useEffect(() => {
        dispatch(getAppointment());
    }, [dispatch]);

    // useEffect(()=>{
    //     window.location.reload()
    // }, [cartData?.data?.length])

    return (
        <>
            <div className="container-fluid mt-5 pb-5">
                <div className="mainheadig mx-3">
                    <h4 className="text-white font-weight-bold">Appointments List</h4>
                </div>
                {cartData?.data?.length > 0 ? (
                    <AdminTable tableHeaders={tableHeaders} tableData={cartData.data} />
                ) : 'Loadin.......'}
            </div>

            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">View</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            {/* {viewData?.original?.name} */}
                            <br />
                            <img
                                src={`${process.env.REACT_APP_BACKEND}appointment_report/${viewData?.original?.patient_report_img}`}
                                alt={viewData?.original?.name}

                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Appointment;
