import { createSlice } from '@reduxjs/toolkit'

const initialState = 
    {
        user: null,
        response: null,
        loading: false,
        error: null,
        callTimes: 0,
        message: '',
        data: []
    }

export const appointmentSlice = createSlice({
    name: 'appointments',
    initialState,
    reducers: {
        getDashboardDataa: () => {
        },
        getAppointment: (state) => {
            state.loading = true;
        },
        removeAppointment: (state) => {
            state.loading = true;
        },
        updateAppointment: (state, action) => {
            state.value += action.payload
        },
        successAppointment: (state, action) => {
            console.log('kkkkkkkkkkkkkkkkkkkkkkkk', action.payload, state?.data)
            state.loading = false;
            state.data = action.payload
        },
        failedAppointment: (state, action) => {
            state.value += action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { getAppointment, removeAppointment, updateAppointment, successAppointment, failedAppointment, getDashboardDataa } = appointmentSlice.actions

export default appointmentSlice.reducer