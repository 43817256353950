import React from 'react'
import 'App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./pages/Login";
import Home from "./pages/Home";
import PrivateRouter from './routers/PrivateRouter';
import OrderList from 'pages/OrderList';
import Contactus from 'pages/Contactus';
import Appointment from 'pages/appointemnt/Appointment';
import Career from 'pages/Career'
import ProductList from 'pages/product/ProductList';
import AddProduct from 'pages/product/AddProduct';
import UpdateProduct from 'pages/product/UpdateProduct';

// gallery
import GalleryList from 'pages/gallery/List';
import GalleryAdd from 'pages/gallery/Add';
import GalleryUpdate from 'pages/gallery/Update';

// media
import MediaList from 'pages/media/List';
import MediaAdd from 'pages/media/Add';
import MediaUpdate from 'pages/media/Update';



import Enquire from 'pages/Enquire';

// banner
import BannerList from 'pages/banner/List';
import AddBanner from 'pages/banner/Add';
import UpdateBanner from 'pages/banner/Update';

// Our Team
import AddOurteam from 'pages/ourteam/AddOurteam';
import ListOurteam from 'pages/ourteam/ListOurteam';
import UpdateOurteam from 'pages/ourteam/UpdateOurteam';
import CreateBill from 'components/billing/CreateBill';

//users
import UsersList from 'pages/users/UsersList';
import AddUser from 'pages/users/AddUser';
import UpdateUser from 'pages/users/UpdateUser';
import ListCustomer from 'pages/customer/ListCustomer';
import AddCustomer from 'pages/customer/AddCustomer';
import UpdateCustomer from 'pages/customer/UpdateCustomer';
import BillList from 'components/billing/BillList';
import BillDetails from 'components/billing/BillDetails';
import CorderList from 'pages/orderList/CorderList';

function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route path="/signin" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </>
      <Routes>
        <Route element={<PrivateRouter />}>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/orderlist" element={<OrderList />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/appointments" element={<Appointment />} />
          <Route path="/career" element={<Career />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/update/:id" element={<UpdateProduct />} />

          {/* gallery */}
          <Route path="/gallery" element={<GalleryList />} />
          <Route path="/gallery/add" element={<GalleryAdd />} />
          <Route path="/gallery/update/:id" element={<GalleryUpdate />} />

          {/* media */}
          <Route path="/media" element={<MediaList />} />
          <Route path="/media/add" element={<MediaAdd />} />
          <Route path="/media/update/:id" element={<MediaUpdate />} />


          {/* Enquire */}
          <Route path='enquire' element={<Enquire />} />

          {/* banner */}
          <Route path="/banner" element={<BannerList />} />
          <Route path="/banner/add" element={<AddBanner />} />
          <Route path="/banner/update/:id" element={<UpdateBanner />} />

          {/* Our team */}
          <Route path="/ourteam" element={<ListOurteam />} />
          <Route path="/ourteam/add" element={<AddOurteam />} />
          <Route path="/ourteam/update/:id" element={<UpdateOurteam />} />


          {/* user  */}
          <Route path="/users" element={<UsersList />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/update/:id" element={<UpdateUser />} />

          {/* billing */}
          <Route path="/billing" element={<CreateBill />} />
          <Route path="/billing-list" element={<BillList />} />
          <Route path="/billing/details/:id" element={<BillDetails />} />

          {/* Customer */}
          <Route path="/customer" element={<ListCustomer />} />
          <Route path="/customer/add" element={<AddCustomer />} />
          <Route path="/customer/update/:id" element={<UpdateCustomer />} />

          {/* customer order list */}
          <Route path="/customerorder" element={<CorderList />} />


          <Route path="/*" element={<h1>No page avaliable</h1>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
